import {
  BetSlipState,
  CalculateCashoutResult,
  CalculateCashoutResultType,
  CashoutRequest,
  CashoutResult,
  CashoutSubmitResult,
  SubmitResultState,
  UserChangeHandling,
} from '@arland-bmnext/api-data'
import { CashoutBetSlipResultType } from '@arland-bmnext/api-data/bets/cashoutBetSlipResultType'
import { faArrowUpFromBracket, faCaretDown, faCaretUp, faCheckCircle, faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence } from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import { useCommonContext } from '../../../context/common.context'
import { calculateCashout, cashoutBet, getCashoutState } from '../../../lib/bets'
import { useSportsBettingSettings } from '../../../lib/content'
import { useUserAccounts } from '../../../lib/user'
import { formatAdvancedDate } from '../../../util/date'
import { EnumTranslator } from '../../../util/enum-translator'
import { formatMoney } from '../../../util/number'
import { ContainedButton } from '../../core/Button'
import Tag from '../../core/Tag'
import { BetListItemExpandedArea } from './BetListItemExpandedArea'
import HelpText from '../../HelpText'
import { getFormattedOddValue } from '../../../util/odds'
import { copyTextToClipboard } from '../../../util/clipboard'
import dynamic from 'next/dynamic'
import { useOnScreen } from '../../../hooks/useIsInViewport'
import { faCoinFront } from '@fortawesome/pro-solid-svg-icons'
import { ErrorMessage } from '../../core/Message'
import { ClipLoader } from 'react-spinners'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

const ShareBetslipImageModal = dynamic(() => import('../modals/ShareBetslipImageModal'))
const CashoutConfirmationModal = dynamic(() => import('../modals/CashoutConfirmationModal'))

type CashoutBetState = 'none' | 'submitted' | 'processed_successful' | 'processed_failed'

export const BetsListItem = ({ bet, onBetCashedOut, hideBetId = false, cashoutCalculation = null }) => {
  const { t } = useTranslation('bets')
  const { mutateUserAccounts, freebetAccount } = useUserAccounts()
  const sportsBettingSettings = useSportsBettingSettings()
  const { getCurrencyById, oddsFormat } = useCommonContext()
  const currency = getCurrencyById(bet.currencyId)
  const [expanded, setExpanded] = useState<boolean>(false)

  const [cashoutState, setCashoutState] = useState<CashoutBetState>('none')
  const [showCashoutConfirmationModal, setShowCashoutConfirmationModal] = useState(false)
  const [showShareBetslipImageModal, setShowShareBetslipImageModal] = useState(false)
  const [calculatedCashout, setCalculatedCashout] = useState<CalculateCashoutResult>(cashoutCalculation)
  const [cashoutSubmitRequest, setCashoutSubmitRequest] = useState<CashoutRequest>(null)
  const [cashoutSubmitResult, setCashoutSubmitResult] = useState<CashoutSubmitResult>(null)
  const [cashoutResult, setCashoutResult] = useState<CashoutResult>(null)
  const [cashoutDelay, setCashoutDelay] = useState(null)
  const cashoutAmountChanged =
    cashoutSubmitRequest &&
    calculatedCashout &&
    cashoutResult &&
    (cashoutResult.cashoutResultType === CashoutBetSlipResultType.CashoutAmountDecreased ||
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.CashoutAmountIncreased)

  const isFreebet = bet.accountId === freebetAccount?.id || bet.isFreeBet
  const isBoosterBet = bet.isBoosterBet
  const isRiskFreeBet = bet.isRiskFreeBet

  const ref = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(ref)
  const isInViewportRef = useRef(isInViewport)

  bet?.items?.forEach((item) => {
    if (item?.game?.name === 'Longterm Markets') {
      item.game.name = t('common:Common.longtermMarkets')
    }
  })

  useEffect(() => {
    isInViewportRef.current = isInViewport
  }, [isInViewport])

  useEffect(() => {
    let cashoutCheckInterval = null

    if (bet.state === BetSlipState.Open && sportsBettingSettings.cashoutEnabled) {
      if (cashoutCalculation == null) {
        loadCashoutCalculation(false)
        cashoutCheckInterval = setInterval(loadCashoutCalculation, 15000 + Math.random() * 3000)
      }
    }

    if (bet.state !== BetSlipState.Open && cashoutCheckInterval != null) {
      clearInterval(cashoutCheckInterval)
    }

    return () => {
      if (cashoutCheckInterval) clearInterval(cashoutCheckInterval)
    }
  }, [bet?.state])

  useEffect(() => {
    setCalculatedCashout(cashoutCalculation)
  }, [cashoutCalculation])

  useEffect(() => {
    handleCashoutSubmitResult()
  }, [cashoutSubmitResult])

  useEffect(() => {
    handleCashoutResult()
  }, [cashoutResult])

  const toggleExpanded = async () => {
    setExpanded(!expanded)
  }

  const loadCashoutCalculation = async (checkIsInViewport: boolean = true) => {
    if (checkIsInViewport && isInViewportRef.current === false) return

    const _calculatedCashout = await calculateCashout(bet.id)
    setCalculatedCashout(_calculatedCashout)
    if (_calculatedCashout.calculationResultType !== CalculateCashoutResultType.CashoutPossible) {
      setCashoutSubmitRequest(null)
      setCashoutSubmitResult(null)
      setCashoutState('none')
    }
  }

  const getColorForBetState = (): { backgroundColor: string; textColor: string } => {
    switch (bet?.state) {
      case BetSlipState.Open:
        return { backgroundColor: 'bg-primary', textColor: 'text-primaryContrast' }
      case BetSlipState.CashedOut:
        return { backgroundColor: 'bg-[#EDC124]', textColor: 'text-black' }
      case BetSlipState.Paid:
      case BetSlipState.Won:
        return { backgroundColor: 'bg-[#3AC560]', textColor: 'text-white' }
      case BetSlipState.Cancelled:
      case BetSlipState.Lost:
        return { backgroundColor: 'bg-[#4E4B66]', textColor: 'text-white' }
      default:
        return null
    }
  }

  const handleCashoutSubmitResult = () => {
    if (cashoutSubmitResult == null) return

    if (cashoutSubmitResult.state === SubmitResultState.Submitted) {
      setCashoutState('submitted')
      setTimeout(() => checkCashoutState(), calculatedCashout.cashoutDelayMSec)
    } else if (cashoutSubmitResult.state === SubmitResultState.Processed) {
      setCashoutResult(cashoutSubmitResult.result)
    }
  }

  const handleCashoutResult = () => {
    if (cashoutResult == null) return

    if (
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.SuccessCashoutAmountUnchanged ||
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.SuccessCashoutAmountIncreased ||
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.SuccessCashoutAmountDecreased
    ) {
      setCashoutState('processed_successful')
      onBetCashedOut(bet.id)
      mutateUserAccounts()
      setTimeout(() => {
        setCashoutResult(null)
        setCashoutState('none')
        setCashoutSubmitRequest(null)
        setCashoutSubmitResult(null)
        setCalculatedCashout(null)
      }, 5000)
    } else if (
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.CashoutAmountDecreased ||
      cashoutResult.cashoutResultType === CashoutBetSlipResultType.CashoutAmountIncreased
    ) {
      setCalculatedCashout(cashoutResult.calculationResult)
      setCashoutState('processed_failed')
    } else {
      setCashoutState('processed_failed')
    }
  }

  const checkCashoutState = async () => {
    if (cashoutSubmitResult) {
      const state = await getCashoutState(cashoutSubmitResult.guid)

      if (state?.result == null) {
        setTimeout(() => checkCashoutState(), 1000)
        return
      }

      setCashoutResult(state.result)
    }
  }

  const onCashoutBet = async () => {
    setShowCashoutConfirmationModal(true)
  }

  const onConfirmCashout = async () => {
    setShowCashoutConfirmationModal(false)
    doCashoutBet()
  }

  const onShareBetslipImage = async () => {
    setShowShareBetslipImageModal(true)
  }

  const doCashoutBet = async () => {
    setCashoutState('none')

    let interval = null
    setCashoutDelay(Math.round(calculatedCashout?.cashoutDelayMSec / 1000))

    if (calculatedCashout.cashoutDelayMSec != null && calculatedCashout.cashoutDelayMSec > 0) {
      interval = setInterval(() => setCashoutDelay((delay) => Math.round(delay - 1)), 1000)
    }

    const cashoutRequest: CashoutRequest = {
      acceptedAmount: calculatedCashout.cashoutAmount,
      userChangeHandling: UserChangeHandling.AcceptAllChanges,
    }
    setCashoutSubmitRequest(cashoutRequest)

    const _cashoutSubmitResult = await cashoutBet(bet.id, cashoutRequest)
    setCashoutSubmitResult(_cashoutSubmitResult)

    return () => {
      if (interval) clearInterval(interval)
    }
  }

  const isCashoutPossible = () => {
    return calculatedCashout?.calculationResultType === CalculateCashoutResultType.CashoutPossible
  }

  const getTitle = () => {
    const itemCount = bet?.items?.length
    const systemCombination = bet?.systems[0]?.combination

    if (itemCount === 1) {
      const game = bet.items[0]?.game
      if (game) {
        return game.name
      }
      return t('BetsPage.singleBet')
    } else if (itemCount === systemCombination) {
      return t('BetsPage.combiOf', { combination: itemCount })
    } else {
      return `${t('BetsPage.systemBet')} ${systemCombination}/${itemCount}`
    }
  }

  return (
    <div ref={ref} className="bets-list-item flex flex-col justify-start">
      <div className="bets-list-item-inner flex flex-col p-3 bg-card text-cardContrast hover:bg-cardHover rounded-md border-[1px] border-neutral">
        <div
          className="bets-list-item-details flex flex-col space-y-1 w-full whitespace-nowrap cursor-pointer"
          onClick={toggleExpanded}
        >
          <div className="bets-list-item-details-row flex justify-between items-center space-x-2">
            <div className="flex space-x-1 items-center overflow-hidden">
              <Tag label={EnumTranslator(t, BetSlipState, bet?.state)} {...getColorForBetState()} />
              <span className="text-base font-semibold overflow-hidden overflow-ellipsis">{getTitle()}</span>
            </div>
            <div className="flex flex-shrink-0">
              <div className="text-xxs font-medium opacity-60">{formatAdvancedDate(t, bet?.createdDate)}</div>
            </div>
          </div>

          {bet?.itemCount > 1 && (
            <div className="bets-list-item-details-row flex justify-between items-center space-x-4">
              <span className="text-xxs opacity-60 overflow-hidden overflow-ellipsis">
                {bet?.items?.reduce((prv, cur, index) => (prv += `${index > 0 ? ', ' : ''}${cur.game?.name}`), '')}
              </span>
            </div>
          )}

          <div className="bets-list-item-details-row flex justify-between items-center text-xs">
            <span>{t('BetsPage.yourStake')}</span>
            <div className="flex space-x-2 items-center font-semibold">
              {isFreebet && (
                <Tag>
                  <HelpText
                    displayLabel
                    title={t('BetSlip.freebetHelpTextTitle')}
                    description={t('BetSlip.freebetHelpTextDescription')}
                  />
                </Tag>
              )}
              {isRiskFreeBet && (
                <Tag>
                  <HelpText
                    displayLabel
                    title={t('BetSlip.riskFreeToken.title')}
                    description={t('BetSlip.riskFreeToken.description')}
                  />
                </Tag>
              )}
              <span>{formatMoney(bet.stake, currency)}</span>
            </div>
          </div>

          {bet?.stakeBonus > 0 && (
            <div className="bets-list-item-details-row flex justify-between items-center text-xs">
              <div className="flex items-center space-x-1">
                <span>{t('BetsPage.stakeBonus')}</span>
                <HelpText
                  title={t('BetSlip.stakeBonus.title', {
                    amount: formatMoney(bet.stakeBonus, currency),
                  })}
                  description={t('BetSlip.stakeBonus.description')}
                />
              </div>
              <div className="flex space-x-2 items-center font-semibold">
                <span>{formatMoney(bet.stakeBonus, currency)}</span>
              </div>
            </div>
          )}

          {sportsBettingSettings?.oddsDisplay?.betSlip?.showTotalMaximumOdds && (
            <div className="bets-list-item-details-row flex justify-between items-center text-xs">
              <div className="flex">
                <span>{t('BetsPage.maxOdd')}</span>
              </div>
              <div className="flex space-x-2 items-center font-semibold">
                {isBoosterBet && (
                  <Tag>
                    <HelpText
                      displayLabel
                      title={t('BetsPage.boosterBet', { boosterPercentage: bet.boosterPercentage })}
                      description={t('BetSlip.betBoostToken.description')}
                    />
                  </Tag>
                )}
                <span>{getFormattedOddValue(oddsFormat, bet.initialMaximumOdd)}</span>
              </div>
            </div>
          )}

          <div className="bets-list-item-details-row flex justify-between items-center text-xs">
            <BetsListItemAmount bet={bet} />
          </div>

          {bet?.gainBonus > 0 && (
            <div className="bets-list-item-details-row flex justify-between items-center text-xs">
              <div className="flex items-center space-x-1">
                <span>{t('BetsPage.gainBonus')}</span>
                <HelpText
                  title={t('BetSlip.gainBonus.title', {
                    amount: formatMoney(bet.gainBonus, currency),
                  })}
                  description={t('BetSlip.gainBonus.description')}
                />
              </div>
              <div className="flex space-x-2 items-center font-semibold">
                <span>{formatMoney(bet.gainBonus, currency)}</span>
              </div>
            </div>
          )}
        </div>

        <AnimatePresence>{expanded && <BetListItemExpandedArea bet={bet} />}</AnimatePresence>

        <AnimatePresence>
          {showCashoutConfirmationModal && (
            <CashoutConfirmationModal
              cashoutAmount={calculatedCashout.cashoutAmount}
              currency={currency}
              onCancel={() => setShowCashoutConfirmationModal(false)}
              onConfirm={onConfirmCashout}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showShareBetslipImageModal && (
            <ShareBetslipImageModal
              bet={bet}
              t={t}
              sportsBettingSettings={sportsBettingSettings}
              currency={currency}
              title={getTitle()}
              oddsFormat={oddsFormat}
              onCancel={() => setShowShareBetslipImageModal(false)}
            ></ShareBetslipImageModal>
          )}
        </AnimatePresence>

        {sportsBettingSettings.cashoutEnabled && (
          <div className="bets-list-item-cashout flex flex-col space-y-2 mt-3">
            {cashoutState === 'processed_failed' && (
              <ErrorMessage className={'!mb-0 text-xs'}>
                {EnumTranslator(t, CashoutBetSlipResultType, cashoutResult.cashoutResultType)}
              </ErrorMessage>
            )}

            {cashoutState === 'processed_successful' && (
              <ContainedButton
                backgroundColor="bg-green-600"
                textColor="text-white"
                borderColor="border-none"
                className="!opacity-100"
                disabled
                fullWidth
                rounded
              >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                {t('BetsPage.cashoutBetSuccessful')}
              </ContainedButton>
            )}

            {isCashoutPossible() && cashoutState !== 'processed_successful' && (
              <div className="bets-list-item-cashout-available flex w-full justify-center">
                <ContainedButton
                  onClick={onCashoutBet}
                  borderColor="border-none"
                  backgroundColor="bg-gray-500 bg-opacity-10"
                  textColor="text-cardContrast"
                  disabled={cashoutState === 'submitted'}
                  className="w-full space-x-2 text-sm rounded-md"
                >
                  {cashoutState === 'submitted' && (
                    <div className="flex justify-center items-center mr-2">
                      <ClipLoader color={'#9E9E9E'} size={20} />
                      {cashoutDelay != null && cashoutDelay >= 1 && (
                        <span className="betslip-cashout-delay absolute font-semibold text-white text-xs">
                          {cashoutDelay}
                        </span>
                      )}
                    </div>
                  )}
                  <span className="bets-list-item-cashout-button-icon">🐓</span>
                  <span className="bets-list-item-cashout-button-icon-alt text-amber-400 hidden max-w-3.5">
                    <FontAwesomeIcon icon={faCoinFront} />
                  </span>
                  <span className="bets-list-item-cashout-button-label">{t('BetsPage.cashoutBet')}</span>

                  <Tag
                    label={formatMoney(
                      cashoutAmountChanged ? cashoutSubmitRequest.acceptedAmount : calculatedCashout.cashoutAmount,
                      currency,
                    )}
                    backgroundColor={'bg-gray-700'}
                    textColor={'text-gray-100'}
                  />

                  {cashoutAmountChanged && (
                    <>
                      <FontAwesomeIcon icon={faArrowRight} />
                      <Tag
                        className={`cashout-amount-change flex space-x-1 items-center ${
                          calculatedCashout.cashoutAmount - cashoutSubmitRequest.acceptedAmount > 0
                            ? 'cashout-amount-change-up bg-green-600 text-white'
                            : 'cashout-amount-change-down bg-red-600 text-white'
                        } text-white`}
                      >
                        <FontAwesomeIcon
                          className={'text-sm leading-[0px]'}
                          icon={
                            calculatedCashout.cashoutAmount - cashoutSubmitRequest.acceptedAmount > 0
                              ? faCaretUp
                              : faCaretDown
                          }
                        />
                        <span className="cashout-amount-change-value">
                          {formatMoney(calculatedCashout.cashoutAmount, currency)}
                        </span>
                      </Tag>
                    </>
                  )}
                </ContainedButton>
              </div>
            )}

            {bet.state === BetSlipState.Open &&
              calculatedCashout?.calculationResultType !== CalculateCashoutResultType.CashoutPossible && (
                <div className="bets-list-item-cashout-unavailable flex w-full justify-center">
                  <ContainedButton
                    borderColor="border-none"
                    backgroundColor="bg-gray-500 bg-opacity-5"
                    textColor="text-gray-500"
                    disabled={true}
                    className="w-full space-x-2 text-sm rounded-md"
                  >
                    <span className="bets-list-item-cashout-button-icon">🐓</span>
                    <span className="bets-list-item-cashout-button-icon-alt text-amber-400 hidden max-w-3.5">
                      <FontAwesomeIcon icon={faCoinFront} />
                    </span>
                    <span className="bets-list-item-cashout-button-label">{t('BetsPage.cashoutBetNotPossible')}</span>
                  </ContainedButton>
                </div>
              )}
          </div>
        )}

        {(bet?.state == BetSlipState.Won ||
          bet?.state == BetSlipState.Lost ||
          bet?.state == BetSlipState.Paid ||
          bet?.state == BetSlipState.CashedOut) &&
          expanded && (
            <ContainedButton
              onClick={() => setShowShareBetslipImageModal(true)}
              borderColor="border-none"
              backgroundColor="bg-gray-500 bg-opacity-10"
              textColor="text-cardContrast"
              className="w-full space-x-2 text-sm rounded-md "
            >
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
              <span>{t('BetSlipImageShare.openModal')}</span>
            </ContainedButton>
          )}
      </div>

      <div className="bets-list-item-cashout-available flex w-full justify-center">
        {expanded && !hideBetId && (
          <div className="bets-list-item-id flex justify-center mt-2 text-xs opacity-50">
            <div>
              ID: {bet?.id}
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => copyTextToClipboard(bet?.id?.toString(), t)}
                className="px-2 cursor-pointer"
              ></FontAwesomeIcon>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const BetsListItemAmount = ({ bet }) => {
  const { t } = useTranslation('bets')
  const { getCurrencyById } = useCommonContext()
  const currency = getCurrencyById(bet.currencyId)

  const getAmount = () => {
    switch (bet.state) {
      case BetSlipState.Open:
        return (
          <>
            <span className="flex">{t('BetsPage.yourMaxReturn')}</span>
            <span className="flex font-semibold">{formatMoney(bet.initialMaximumReturnedAmount, currency)}</span>
          </>
        )
      default:
        return (
          <>
            <span className="flex">{t('BetsPage.yourReturn')}</span>
            <span className="flex font-semibold">{formatMoney(bet.returnedAmount, currency)}</span>
          </>
        )
    }
  }

  return <>{getAmount()}</>
}

export const BetsListItemSkeleton = ({ width = 'w-full' }) => {
  return (
    <div className={'bets-list-item-skeleton flex flex-col flex-shrink-0 space-y-3 p-3 rounded-lg skeleton ' + width}>
      <div className="flex flex-col space-y-1">
        <div className="flex justify-between">
          <div className="skeleton-content w-1/3 h-6"></div>
          <div className="skeleton-content w-1/2 h-6"></div>
        </div>
        <div className="flex justify-between">
          <div className="skeleton-content w-1/5 h-4"></div>
          <div className="skeleton-content w-1/4 h-4"></div>
        </div>
        <div className="flex justify-between">
          <div className="skeleton-content w-1/5 h-4"></div>
          <div className="skeleton-content w-1/4 h-4"></div>
        </div>
      </div>
      <div className="skeleton-content w-full rounded-lg h-9"></div>
    </div>
  )
}
