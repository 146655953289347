import { BetItem, BetSlipItemType, GameLiveState, GameType, MarketOptions, OddState } from '@arland-bmnext/api-data'
import { faCheckCircle, faChevronRight, faMinusCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLiveContext } from '../../../context/live.context'
import { useSportsBettingSettings } from '../../../lib/content'
import { buildEventUrl } from '../../../util/url'
import ConditionalLink from '../../core/ConditionalLink'
import Tag, { BetBuilderTag } from '../../core/Tag'
import GameTime from '../../odds/GameTime'
import { useGamePeriods } from '../../../lib/odds'
import useCurrentLanguage from '../../../hooks/useCurrentLanguage'
import LiveIcon from '../../odds/LiveIcon'
import BetBuilderOdd from '../../odds/BetBuilderOdd'
import { formatAdvancedDate } from '../../../util/date'
import useTranslation from 'next-translate/useTranslation'
import { useCommonContext } from '../../../context/common.context'
import { getFormattedOddValue } from '../../../util/odds'
import MatchtrackerIcon from '../../../public/matchtracker.svg'
import { useEffect, useState } from 'react'
import useSportradarWidget from '../../../hooks/useSportradarWidget'
import useSportradarWidgetSettings from '../../../hooks/useSportradarWidgetSettings'

export const BetListItemExpandedAreaItem = ({
  betItem,
  isSharedBet = false,
}: {
  betItem: BetItem
  isSharedBet?: boolean
}) => {
  const { t } = useTranslation('bets')
  const { oddsFormat } = useCommonContext()
  const liveContext = useLiveContext()
  const sportsBettingSettings = useSportsBettingSettings()
  const language = useCurrentLanguage()
  const gamePeriods = useGamePeriods(language?.id)
  const { addMatchtracker, removeWidget } = useSportradarWidget()
  const { lmtEnabled } = useSportradarWidgetSettings()

  const [matchTrackerActive, setMatchTrackerActive] = useState(false)
  const [matchtrackerError, setMatchtrackerError] = useState<boolean>(false)

  const liveGame = liveContext.liveGames?.find((game) => game.id === betItem?.gameId)
  const currentPeriod = gamePeriods?.find((gamePeriod) => gamePeriod.id === liveGame?.liveStatus?.currentPeriodId)
  const gameTime = liveGame?.liveStatus?.gameTime

  const isBettableGame = betItem?.odd?.state === OddState.Open || betItem?.odd?.state === OddState.Stopped
  const isBanker = betItem?.type === BetSlipItemType.Banker

  const isBetBuilderMarket =
    (betItem?.market?.options & MarketOptions.BetBuilderMarket) === MarketOptions.BetBuilderMarket

  const isMatchTrackerPossible =
    lmtEnabled &&
    !matchtrackerError &&
    (liveGame?.type === GameType.OneOnOne || betItem?.game?.type === GameType.OneOnOne) &&
    (liveGame?.externalId || betItem?.game?.externalId)

  useEffect(() => {
    if (matchTrackerActive) {
      addMatchtracker(`sir-matchtracker-${betItem?.gameId}`, liveGame?.externalId ?? betItem?.game?.externalId, () => {
        removeWidget(`sir-matchtracker-${betItem?.gameId}`)
        setMatchtrackerError(true)
      })
    } else {
      removeWidget(`sir-matchtracker-${betItem?.gameId}`)
    }

    return () => {
      removeWidget(`sir-matchtracker-${betItem?.gameId}`)
    }
  }, [matchTrackerActive])

  const getIconForOddState = () => {
    switch (betItem.odd?.state) {
      case OddState.Won:
        return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
      case OddState.Cancelled:
        return <FontAwesomeIcon icon={faMinusCircle} className="text-orange-500" />
      case OddState.Dead:
      case OddState.Lost:
        return <FontAwesomeIcon icon={faTimesCircle} className="text-red-500" />
    }
  }

  const onMatchTrackerClick = () => {
    setMatchTrackerActive(!matchTrackerActive)
  }

  return (
    <div
      className="bet-list-item-expanded-area-item relative flex flex-col pb-2 text-xs border-b-[1px] border-neutral last:border-none"
      data-gid={betItem?.gameId}
    >
      {isSharedBet && (
        <div className="bet-list-item-expanded-area-item-game-date text-xxs font-medium opacity-60 whitespace-nowrap">
          {formatAdvancedDate(t, betItem?.game?.startDate)}
        </div>
      )}

      <div className="bet-list-item-expanded-area-item-row flex space-x-2 justify-between">
        <div className="bet-list-item-expanded-area-item-game-name flex font-bold justify-between items-center space-x-1 overflow-hidden">
          <ConditionalLink
            href={
              isBettableGame
                ? buildEventUrl(betItem?.game?.id, betItem?.game?.name, betItem?.game?.usesUsDisplay)
                : null
            }
          >
            <span
              className={`overflow-hidden ${isBettableGame ? 'text-primary flex space-x-1 items-center cursor-pointer hover:underline' : ''}`}
            >
              <div className="flex items-center space-x-2 overflow-hidden">
                {isBetBuilderMarket && <BetBuilderTag useShortSign />}{' '}
                <span className="overflow-normal">
                  {betItem?.game?.name}
                  {isBettableGame && (
                    <>
                      {' '}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </>
                  )}
                </span>
              </div>
            </span>
          </ConditionalLink>
          {getIconForOddState()}
        </div>

        {!isSharedBet && (
          <>
            {liveGame ? (
              <>
                {(!matchTrackerActive || matchtrackerError) && (
                  <div className="bet-list-item-expanded-area-item-game-live flex space-x-1 items-center bg-live text-liveContrast rounded-full px-2 whitespace-nowrap">
                    <div className="h-[10px]">
                      <LiveIcon color="text-liveContrast" />
                    </div>
                    <span className="font-semibold">
                      {liveGame.liveStatus?.homeScore}:{liveGame.liveStatus?.awayScore} |
                    </span>
                    {liveGame.liveState === GameLiveState.Live && (
                      <>
                        {currentPeriod && <span className="opacity-60">{currentPeriod?.shortSign}</span>}
                        {gameTime && currentPeriod?.period != -1 && (
                          <span className="font-bold font-mono">
                            <GameTime gameTime={gameTime} currentPeriod={currentPeriod} />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <span className="bet-list-item-expanded-area-item-game-date text-xxs font-medium opacity-60 whitespace-nowrap">
                {formatAdvancedDate(t, betItem?.game?.startDate)}
              </span>
            )}
          </>
        )}
      </div>

      <div className="bet-list-item-expanded-area-item-row flex justify-between items-start space-x-2">
        <div className="bet-list-item-expanded-area-item-odd-name flex items-center space-x-1">
          {isBetBuilderMarket ? (
            <BetBuilderOdd oddName={betItem?.odd?.name} dotBackgroundColor={'bg-card'} />
          ) : (
            <div className="flex flex-col">
              <div className="flex space-x-1 items-center">
                <span className="text-xs font-medium">{betItem?.market?.name}</span>
                {isBanker && <Tag label={'B'} className="!h-3 !w-3" />}
              </div>
              <div className="text-xs font-medium opacity-60">
                <span>{betItem?.odd?.name}</span>
                {sportsBettingSettings?.oddsDisplay?.userBets?.showOdds && (
                  <span> @ {getFormattedOddValue(oddsFormat, betItem?.initialOddValue)}</span>
                )}
              </div>
            </div>
          )}
        </div>

        {isMatchTrackerPossible && !isSharedBet && (
          <div className="flex items-center shrink-0 cursor-pointer" onClick={onMatchTrackerClick}>
            <MatchtrackerIcon
              className={`flex shrink-0 h-[24px] w-[24px] ${matchTrackerActive ? 'text-primary' : ''}`}
            />
          </div>
        )}
      </div>

      {isMatchTrackerPossible && <div id={`sir-matchtracker-${betItem?.gameId}`} className="z-0"></div>}
    </div>
  )
}
